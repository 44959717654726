import React, { useEffect, useState } from 'react';
import './Header.css'
import { Link, NavLink,  useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png';

import menuIcon from '../../assets/menu_icon.svg';



const Header = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
   const location = useLocation();

    useEffect(() =>{
        setToggleMenu(false)
    },[location.pathname])
  return (
    <div  className='header-wrapper d-flex align-center'>
    <header className="wrapper d-flex justify-between align-center">
       <div className='toogleMenu d-flex center'>
       <button className='d-none' onClick={() =>setToggleMenu(!toggleMenu)} >
                <img src={menuIcon} alt="close" />
            </button>
       <div className="logo">
       
            <NavLink to="/">
                <img src={logo} alt="Jindipt" />
            </NavLink>
        
        </div>
        
       </div>
        <nav className={`d-flex justify-between ${toggleMenu && "open"}`}>
            <ul className='d-flex gap-20 menu'>
          
            <li>
                    <NavLink to="">Home</NavLink>
                </li>
             
                <li className='dropdown-button'>
                    <a href="#" >Products<span className='downloadarrow'><i class="fa-solid fa-angle-down"></i></span></a>
                    <ul className='submenu'>
                        <li>
                            <NavLink to="/payment-gateway">Payment Gateway</NavLink>
                        </li>
                       
                        <li>
                            <NavLink to="/web-checkout">Web Checkout</NavLink>
                        </li>
                       
                        <li>
                            <NavLink to="/mobile-app-sdk">Mobile App SDK</NavLink>
                        </li>
                        <li>
                            <NavLink to="/recurring-payments">Recurring Payments</NavLink>
                        </li>
                        <li>
                            <NavLink to="/instant-refund">Instant Refund</NavLink>
                        </li>
                        
                    </ul>
                </li>
               
                <li>
                    <NavLink to="/about">About Us</NavLink>
                </li>
                <li>
                    <NavLink to="/contact">Contact Us</NavLink>
                </li>
                
                
            </ul>
        </nav>
      
     
    </header>
    
  
</div>
  );
}

export default Header;
