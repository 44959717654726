import React from 'react';
import './Contact.css';
import Banner from '../../Componants/Banner/Banner';
import banner from "../../assets/banner.png";

const Contact = () => {

    const bannar = {
        banner: banner,
        h1: "Contact Us",
        button: "Let's Work Together!",
        buttonLink: "/contact",
        p: "You deserve to be seen. We are here to achieve your goal.",
      };
  return (
   <>
    <Banner banner={bannar} />
    <div className='contact-wrapper'>
      <div className="wrapper d-flex justify-between  gap-50">
        <div className="contactLeft">
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-location-dot"></i>
                </div>
                <div className="text">
                    <h3>Address</h3>
                    <p>Shop No. W2-281/6, First FloorPhool Bagh, Rampura,
                    Rampura, North West Delhi,Delhi, India, 110035.</p>

                </div>

            </div>
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-envelope-circle-check"></i>
                </div>
                <div className="text">
                    <h3>Email</h3>
                    <p>jindipttechnologypvtltd01@gmail.com</p>

                </div>

            </div>
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-phone"></i>
                </div>
                <div className="text">
                    <h3>Phone</h3>
                    <p>+91-8859782738</p>

                </div>

            </div>

        </div>
       
     
        <div className="map-wrapper" style={{width:"100%"}}>
        <div className="wrapper">
        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d14001.560327399238!2d77.1355363414956!3d28.67797566023476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sFirst%20FloorPhool%20Bagh%2C%20Rampura%2C%20Rampura%2C%20North%20West%20Delhi%2CDelhi%2C%20India%2C%20110035!5e0!3m2!1sen!2sin!4v1720867784352!5m2!1sen!2sin" width="100%" height="100" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      </div>
     
      </div>
      
   
   </>
  );
}

export default Contact;
