import React, { useEffect } from "react";
import "./Home.css";
import Banner from "../../Componants/Banner/Banner";
import content from '../../assets/cardwithboy.png';
import group from'../../assets/groupImage.png'
import f1 from '../../assets/display.png';
import f2 from '../../assets/link.png';
import f3 from '../../assets/file.png';
import f4 from '../../assets/payroll.png';
import f5 from '../../assets/animate5.gif';
import p1 from '../../assets/payment1.jpg';
import p2 from '../../assets/payment2.jpg';
import p3 from '../../assets/payment3.jpg';
import { Link } from "react-router-dom";
import homeBanner from "../../assets/jbanner.png";
import AOS from 'aos';
import 'aos/dist/aos.css';


const Home = () => {
  const bannar = {
    banner: homeBanner,
    h1: "Make Payments Easier",
    h2: "with Online Payments",
    button: "Get Started!",
    buttonLink: "/contact",
    p: "Accept online payments and grow your business.",
  };

  useEffect(() => {
    AOS.init(); 
  }, []);
  return (
    <div>
      <Banner banner={bannar} />
      <div className="main">
        <div className="wrapper">
         
        </div>
        <div className="finetech-wrapper">
          <div className="d-flex align-center gap-50">
            <div className="left" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
               <img className="w-100" src={content} alt="" />
            </div>
            <div className="right" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
             
              <h3 className="h3">Best payment gateway built for India</h3>
              <p className="gray" style={{width:"348px"}}>
              India’s leading brands have trusted Jindipt payments platform to manage online payment collections, vendor payouts and financial operations. Sign up with us and experience the ease of managing payments and financial operations.              </p>
                 
                <div className="mt-20">
                  <h3 className="font-20">Secure APIs</h3>
                  <p className="gray" >Easy to implement and secure APIs designed to provide you with the required flexibility and control.</p>
                </div>
                <div className="mt-20">
                  <h3 className="font-20">Direct connections</h3>
                  <p className="gray">Ensuring greater reliability and an improved user experience with direct integrations across banks, card networks and wallets.</p>
                </div>
             
            

            </div>

          </div>

        </div>
       
        <div className="featured-services-wrapper services-wrapper text-center " style={{marginTop:"-4px"}}>
        
          <h3 className="h3">What We Offer Smart Solution for Your Payment</h3>
          <p style={{marginTop:"-20px", color:"#fff"}}>Full stack technology platform that offers Payment Solutions + SaaS APIs</p>
        <div className=" featured-services d-flex justify-center gap-50" style={{marginTop:"70px"}}>
          <div className="featurer  ">
             <div className="img">
                 <img src={f1} alt="featurer-1" />
             </div>
             <h3>Plug-and-play APIs</h3>
             <p className="grey">
             We offer easy to integrate and developer-friendly plug-and-play APIs 
             that comes with detailed API docs
             </p>
            
           
          </div>
        

          <div className="featurer ">
             <div className="img">
                 <img src={f3} alt="featurer-3" />
             </div>
             <h3>Payouts</h3>
             <p className="grey">
             Copy paste a line of code and collect payments anytime, anywhere. No integration required.
             </p>
            
           
          </div>
          <div className="featurer ">
             <div className="img">
                 <img src={f4} alt="featurer-4" />
             </div>
             <h3>InstaCollect</h3>
             <p className="grey">
             Collect payments instantly through virtual account
             </p>
            
           
          </div>
          <div className="featurer ">
             <div className="img">
                 <img src={f2} alt="featurer-5" />
             </div>
             <h3>Payment Links</h3>
             <p className="grey">
             No website? No problem! Share payment links and get paid in minutes. No coding required.
             </p>
            
             
           
          </div>

        </div>
       
        </div>
       
       
       
        <div className="finetech-wrapper" style={{background:"#F3F3F3"}}>
          <div className="d-flex align-center gap-50">

          <div className="left" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
               <img className="w-100" src={group} alt="" />
            </div>
            
            <div className="right" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true" style={{maxWidth: "562px"}}>
             
              <h3 className="h3"> Payment solutions that are easy to understand, and simple to use</h3>
                         

              <div className="d-flex gap-50">
                <ul>
                  <li>Plug-and-play APIs </li>
                  <li>PCI DSS compliance level-1</li>
                  
                 </ul>
                 <ul>
                  <li>Quick onboarding </li>
                  <li>24*7 technical support</li>
                  
                 </ul>
                  </div> 
                
             

            </div>
          

          </div>

        </div>
       
       
       
       
        <div className="wrapper featured-services-wrapper ecosystem-wrapper text-center ">
        
          <h3 className="h3">Harness our payment ecosystem to improve your cash flow and ease business operations. Get time to expand your business.</h3>
        <div className=" featured-services ecosystem d-flex justify-center gap-50">
          <div className="featurer ">
             <div className="img center">
                <div><i class="fa-solid fa-file-code"></i></div>
             </div>
             <div className="desc">
             <h3>Payment Gateway</h3>
             <p className="grey">
						        Offer your customers a secure payment gateway 150+ payment modes with the best success rates					
             </p>
             </div>
            
           
          </div>
          <div className="featurer ">
             <div className="img center">
             <div><i class="fa-solid fa-code"></i></div>
             </div>
             <div className="desc">
             <h3>No Code Payments</h3>
             <p className="grey">
             Share and accept payments via WhatsApp, SMS, Email. Add buttons to your website, and create invoices 
             </p>
             </div>
            

           
          </div>

          <div className="featurer ">
             <div className="img center">
             <div><i class="fa-solid fa-qrcode"></i></div>
             </div>
             <div className="desc">
             <h3>QR Code	</h3>
             <p className="grey">
             Make payments contactless with 140+ UPI apps. Make in-store payments & doorstep collections digital	
             </p>
             </div>
            
              
          </div>
        

        </div>
        </div>

       
        <div>
        <div className="payAsYou-wrapper">
    
            <div className="wrapper text-center">
              <h2>Pay As You Use Transparent pricing </h2>
              <h3 className="h3">that suits your business</h3>
              
              <div className="center gap-50">
              <p className="font-20 background" >2% for Visa, Mastercard, Net Banking, BNPL, Wallets only domestic transactions
  
  </p>
  <p className="font-20 background">
  3% for Diners, American Express, EMI, International Transactions
  </p>
              </div>
            

            </div>
   
        
        </div>
      
        </div>
    

        
      </div>
    </div>
  );
};

export default Home;
