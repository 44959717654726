import React, { useEffect } from 'react';
import './About.css';
import Banner from '../../Componants/Banner/Banner';
import use1Img from '../../assets/Prakash-Pic.jpg'
import use2Img from '../../assets/user2.jpeg'
import use3Img from '../../assets/ArbaazJamal-Pic.jpg'
import use4Img from '../../assets/faisalnomani-pic.jpg'
import { Link } from 'react-router-dom';
import banner from "../../assets/jbanner.png";

import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {

    const bannar = {
        banner: banner,
        h1: "About Jindipt",
        button: "Meet Our Awesome Team!",
        buttonLink: "/about",
        p: "Jindipt provides payment gateway solutions to online businesses through its cutting-edge and award-winning technology. In India, Jindipt serves more than 1000+ merchants with over 100+ payment methods and is the preferred payment partner for e-commerce merchants, including all leading e-commerce companies and a majority of airline businesses.",
      };

      useEffect(() => {
        AOS.init(); 
      }, []);

  return (
    <div>
        <Banner banner={bannar} />
        <div className="about-wrapper">
            <div className="wrapper about d-flex justify-between gap-50" id="about">
                <div className="about-card"  data-aos="fade-in" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
                    
                    <div className="overLayer">
                        <h2> KARAN GUREJA</h2>
                        <h4>Chief Executive Officer</h4>
                        {/* <div className='center'><Link to="https://www.linkedin.com/in/prakash-ravindran-175a8baa/" target='_blank'><i class="fa-brands fa-linkedin"></i></Link></div> */}
                    </div>
                </div>
                <div className="about-card"  data-aos="fade-in" data-aos-duration="1000" data-aos-delay="300" data-aos-once="true">
                   
                    <div className="overLayer">
                        <h2>ARJUN GUREJA</h2>
                        <h4>Director</h4>
                    </div>
                </div>
              
            </div>
        </div>
      
    </div>
  );
}

export default About;
